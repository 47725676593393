import { Link } from '@remix-run/react';

import { type DtoChannelProgramLink } from '@lp-lib/api-service-client/public';

import { type Organization } from '../../types';
import { ScoreboardIcon } from '../icons/ScoreboardIcon';

export function AnalyticsProgramDetailsLayout(props: {
  organization: Organization;
  programLink: DtoChannelProgramLink;
  children: React.ReactNode;
}) {
  return (
    <div className='w-full h-full px-17.5 flex flex-col items-center text-white'>
      <AnalyticsContainer className='flex flex-col items-center'>
        <header className='w-full'>
          <div className='flex items-center gap-2'>
            <ScoreboardIcon className='w-6 h-6 fill-current' />
            <div className='font-medium text-2xl'>
              {props.organization.name} {props.programLink.basicProgram?.name}{' '}
              Usage Analytics
            </div>
          </div>
          <Link to={`../`} className='text-sms text-icon-gray'>{`< Back`}</Link>
        </header>
        {props.children}
      </AnalyticsContainer>
    </div>
  );
}

export function AnalyticsContainer(props: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={`w-245 xl:w-288 ${props.className ?? ''}`}>
      {props.children}
    </div>
  );
}
