import { NavLink } from '@remix-run/react';
import { useMemo, useRef, useState } from 'react';
import { useTitle } from 'react-use';
import useSWRImmutable from 'swr/immutable';

import { ModelsReportTimeRange } from '@lp-lib/api-service-client/public';

import { useFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { apiService } from '../../../services/api-service';
import {
  TIME_RANGES,
  type TimeRangeKey,
} from '../../../services/api-service/analytics.api';
import { type Organization } from '../../../types';
import { makeTitle } from '../../../utils/common';
import {
  AnalyticsContainer,
  AnalyticsJoyCapture,
  AnalyticsPrograms,
} from '../../Analytics';
import {
  AnalyticsExperienceList,
  AnalyticsSessionsListPage,
  ExportButton,
} from '../../Analytics/AnalyticsExperienceList';
import { AnalyticsProgramDetails } from '../../Analytics/AnalyticsProgramDetails';
import { AnalyticsProgramRoundDetails } from '../../Analytics/AnalyticsProgramRoundDetails';
import { AnalyticsDownloadSlidesBanner } from '../../Analytics/AnalyticsSlides/AnalyticsDownloadSlidesBanner';
import { AnalyticsSummaryV2 } from '../../Analytics/AnalyticsSummary';
import { CopyLinkButtonWithPreview } from '../../common/CopyButton';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons/Arrows';
import { ScoreboardIcon } from '../../icons/ScoreboardIcon';
import { Loading } from '../../Loading';
import { useOrgFeatureContext } from '../OrgFeatureProvider';

export type AnalyticsFilter = {
  timeRange: TimeRangeKey;
  orgId: string;
};

const FILTER_LABEL_MAP: { [key in AnalyticsFilter['timeRange']]: string } = {
  '3months': 'Last 3 months',
  '6months': 'Last 6 months',
  '12months': 'Last 12 months',
  'all time': 'All Time',
};

export function FilterUtils(props: {
  updateFilter: (next: AnalyticsFilter) => void;
  org: Organization;
  filter: AnalyticsFilter;
}): JSX.Element | null {
  const { updateFilter, org, filter } = props;

  const [showFilters, setShowFilters] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleUpdateFilter = async (next: AnalyticsFilter) => {
    setShowFilters(false);
    updateFilter(next);
  };

  useOutsideClick(ref, () => setShowFilters(false));

  return (
    <div ref={ref} className='relative'>
      <button
        type='button'
        className='w-40 h-12.5 rounded-xl btn btn-secondary font-medium flex items-center justify-center text-white'
        onClick={() => setShowFilters(!showFilters)}
      >
        <p className='mr-2'>{FILTER_LABEL_MAP[filter.timeRange]}</p>
        {showFilters ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {showFilters && (
        <div className='w-full bg-black border border-secondary rounded-xl text-3xs absolute mt-0.5 z-10'>
          {TIME_RANGES.map((r) => {
            return (
              <div
                key={r}
                className='w-full hover:bg-secondary pl-7 pr-4 py-4 rounded-xl cursor-pointer'
                onClick={() =>
                  handleUpdateFilter({
                    timeRange: r,
                    orgId: org.id,
                  })
                }
              >
                {FILTER_LABEL_MAP[r]}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export function OrganizationAnalyticsCharts(props: {
  org: Organization;
  filter: AnalyticsFilter;
  recentNSummaryDataPoints?: number;
  exportEnabled?: boolean;
}): JSX.Element {
  const { filter } = props;

  const { isValidating, data } = useSWRImmutable(
    ['/org-summaries', filter],
    async () => {
      const resp = await apiService.analytics.queryOrgSummaries(filter);
      return resp.data;
    },
    {
      shouldRetryOnError: false,
    }
  );

  const paginator = useMemo(
    () =>
      apiService.organization.queryExperiences(filter.orgId, filter.timeRange),
    [filter.orgId, filter.timeRange]
  );

  if (isValidating && !data) {
    return <Loading />;
  }

  return (
    <div className='w-full flex flex-col items-center gap-10'>
      {data && (
        <AnalyticsJoyCapture
          summaries={data?.summaries}
          searchParams={filter}
        />
      )}
      <AnalyticsSummaryV2
        summaries={data?.summaries ?? []}
        recentNSummaryDataPoints={props.recentNSummaryDataPoints}
        upcomingEventsCount={data?.upcomingEventsCount ?? 0}
      />
      <AnalyticsExperienceList
        paginator={paginator}
        export={
          props.exportEnabled ? <ExportButton filter={filter} /> : undefined
        }
      />
    </div>
  );
}

function OrganizationAnalyticsHeader(props: {
  org: Organization;
  filter: AnalyticsFilter;
  updateFilter: (next: AnalyticsFilter) => void;
  showPrograms?: boolean;
}) {
  const { org, filter, updateFilter } = props;
  const { routePrefix } = useOrgFeatureContext();
  const slidesEnabled = useFeatureQueryParam('analytics-slides-download');
  return (
    <div className='w-full pt-10 flex flex-col gap-5'>
      <div className='flex justify-between items-center'>
        <div className='flex items-center'>
          <ScoreboardIcon className='w-6.5 h-6.5 fill-current' />
          <div className='pl-2 font-medium text-2xl'>
            {org.name} Usage Analytics
          </div>
        </div>
        <div className='flex items-center gap-2'>
          <div className='text-tertiary font-bold'>Share this Page:</div>
          <CopyLinkButtonWithPreview urlToCopy={`${window.location.href}`} />
        </div>
      </div>
      {slidesEnabled && (
        <AnalyticsDownloadSlidesBanner organization={org} filter={filter} />
      )}
      <div className='flex justify-between items-center'>
        <div className='flex items-center gap-8'>
          <NavLink
            className={({ isActive }) =>
              `${isActive ? 'font-bold underline' : ''}`
            }
            to={`${routePrefix}/analytics`}
            end
          >
            Overall
          </NavLink>
          {props.showPrograms && (
            <NavLink
              className={({ isActive }) =>
                `${isActive ? 'font-bold underline' : ''}`
              }
              to={`${routePrefix}/analytics/programs`}
            >
              Program Analytics
            </NavLink>
          )}
        </div>
        <FilterUtils updateFilter={updateFilter} org={org} filter={filter} />
      </div>
    </div>
  );
}

export function OrganizationAnalyticsOverall(props: {
  recentNSummaryDataPoints?: number;
  showPrograms?: boolean;
  exportEnabled: boolean;
}): JSX.Element | null {
  useTitle(makeTitle('Analytics Overall'));

  const { org } = useOrgFeatureContext();
  const [filter, setFilter] = useState<AnalyticsFilter>({
    orgId: org.id,
    timeRange: ModelsReportTimeRange.ReportTimeRangeAllTime,
  });

  const updateFilter = (next: AnalyticsFilter) => {
    setFilter({ orgId: org.id, timeRange: next.timeRange });
  };

  return (
    <div className='w-full h-full text-white relative flex flex-col items-center'>
      <AnalyticsContainer className='flex flex-col items-center'>
        <OrganizationAnalyticsHeader
          org={org}
          filter={filter}
          updateFilter={updateFilter}
          showPrograms={props.showPrograms}
        />

        <OrganizationAnalyticsCharts
          org={org}
          filter={filter}
          recentNSummaryDataPoints={props.recentNSummaryDataPoints}
          exportEnabled={props.exportEnabled}
        />
      </AnalyticsContainer>
    </div>
  );
}

export function OrganizationAnalyticsPrograms(): JSX.Element | null {
  useTitle(makeTitle('Analytics Programs'));

  const { org } = useOrgFeatureContext();
  const [filter, setFilter] = useState<AnalyticsFilter>({
    orgId: org.id,
    timeRange: ModelsReportTimeRange.ReportTimeRangeAllTime,
  });

  const updateFilter = (next: AnalyticsFilter) => {
    setFilter({ orgId: org.id, timeRange: next.timeRange });
  };

  return (
    <div className='w-full h-full text-white relative flex flex-col items-center'>
      <AnalyticsContainer className='flex flex-col items-center'>
        <OrganizationAnalyticsHeader
          org={org}
          filter={filter}
          updateFilter={updateFilter}
          showPrograms
        />
        <div className='pt-10 flex flex-col gap-5'>
          <AnalyticsPrograms searchParams={filter} />
        </div>
      </AnalyticsContainer>
    </div>
  );
}

export function OrganizationAnalyticsSessions(): JSX.Element | null {
  const { org } = useOrgFeatureContext();
  return (
    <div className='w-full h-full text-white relative'>
      <div className='w-full pt-10 flex justify-between items-center'>
        <AnalyticsSessionsListPage organization={org} />
      </div>
    </div>
  );
}

export function OrganizationAnalyticsProgramDetails(): JSX.Element | null {
  useTitle(makeTitle('Analytics Programs'));

  const { org } = useOrgFeatureContext();

  return (
    <div className='w-full h-full text-white relative'>
      <div className='w-full pt-10 flex justify-between items-center'>
        <AnalyticsProgramDetails organization={org} />
      </div>
    </div>
  );
}

export function OrganizationAnalyticsProgramRoundDetails(): JSX.Element | null {
  useTitle(makeTitle('Analytics Programs'));

  const { org } = useOrgFeatureContext();

  return (
    <div className='w-full h-full text-white relative'>
      <div className='w-full pt-10 flex justify-between items-center'>
        <AnalyticsProgramRoundDetails organization={org} />
      </div>
    </div>
  );
}
