import { Link } from '@remix-run/react';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import {
  type DtoBasicProgram,
  type DtoChannel,
  type DtoProgramSummaryCelebrations,
  type DtoProgramSummaryGlobalPairing,
  type DtoProgramSummaryIntros,
  type DtoProgramSummaryRecognition,
  type DtoProgramSummaryWaterCooler,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { type TimeRangeKey } from '../../services/api-service/analytics.api';
import { type SlackChannel } from '../../types/slack';
import { fromMediaDTO } from '../../utils/api-dto';
import { ImagePickPriorityLowToHigh, MediaUtils } from '../../utils/media';
import { type Action, ActionSheet } from '../ActionSheet';
import { ArrowRightIcon } from '../icons/Arrows';
import { Loading } from '../Loading';
import { type AnalyticsFilter } from '../Organization/Details/Analytics';

function StatsBox(props: { title: string | number; subtitle: string }) {
  return (
    <div className='w-35 flex flex-col justify-center items-center'>
      <p className='text-2xl font-medium'>{props.title}</p>
      <p className='text-xs font-light'>{props.subtitle}</p>
    </div>
  );
}

function AnalyticsProgramLayout(props: {
  program: DtoBasicProgram;
  channels: DtoChannel[] | SlackChannel[];
  children?: React.ReactNode;
  className?: string;
}) {
  const { program, channels, children, className } = props;

  const mediaUrl = useMemo(
    () =>
      MediaUtils.PickMediaUrl(
        fromMediaDTO(program.basicSettings?.cover?.media),
        {
          priority: ImagePickPriorityLowToHigh,
        }
      ),
    [program.basicSettings?.cover?.media]
  );

  return (
    <div
      className={`w-full p-5 bg-black rounded-xl border border-secondary text-white flex items-center ${className}`}
    >
      <div className='flex-1 flex items-center gap-4'>
        {mediaUrl ? (
          <img src={mediaUrl} className='w-26 h-15 object-cover' alt='' />
        ) : (
          <div className='w-26 h-15'></div>
        )}

        <div className='flex flex-col'>
          <p className='text-base font-bold'>{program.name}</p>
          {channels.length > 0 && (
            <p className='text-2xs text-icon-gray'>
              Slack Channel: {channels.map((c) => `#${c.name}`).join(', ')}
            </p>
          )}
        </div>
      </div>

      {children}
    </div>
  );
}

function getInstalledChannels(
  channels: DtoChannel[],
  programType: EnumsProgramType
) {
  return channels
    .filter((c) =>
      c.programLinks.map((l) => l.programType).includes(programType)
    )
    .sort((a, b) => a.name.localeCompare(b.name));
}

function getBasicProgram(
  channels: DtoChannel[],
  programType: EnumsProgramType
) {
  for (const channel of channels) {
    const programLink = channel.programLinks.find(
      (l) => l.programType === programType
    );
    if (programLink) {
      return programLink.basicProgram;
    }
  }
  return null;
}

function AnalyticsProgramsGlobalPairing(props: {
  program: DtoBasicProgram;
  channels: DtoChannel[];
  summary: DtoProgramSummaryGlobalPairing;
}) {
  const { program, channels, summary } = props;
  return (
    <AnalyticsProgramLayout program={program} channels={channels}>
      <StatsBox
        title={summary.roundsCount}
        subtitle={`${pluralize('Round', summary.roundsCount)} So Far`}
      />

      <StatsBox
        title={summary.pairingCount}
        subtitle={`${pluralize('Pair', summary.pairingCount)} Made`}
      />

      <StatsBox
        title={summary.sessionsCount}
        subtitle={`${pluralize('Experience', summary.sessionsCount)} Played`}
      />
    </AnalyticsProgramLayout>
  );
}

function AnalyticsProgramsIntrosAction(props: { channels: DtoChannel[] }) {
  if (props.channels.length === 0) return null;
  if (props.channels.length === 1) {
    const link = props.channels[0].programLinks.find(
      (l) => l.programType === EnumsProgramType.ProgramTypeIntros
    );
    return (
      <Link to={`./${link?.id}`}>
        <ArrowRightIcon className='w-6 h-6 fill-current' />
      </Link>
    );
  }

  const actions = props.channels.map((c) => {
    const link = c.programLinks.find(
      (l) => l.programType === EnumsProgramType.ProgramTypeIntros
    );

    return {
      key: c.id,
      kind: 'link',
      text: c.name,
      icon: <></>,
      href: `./${link?.id}`,
    } satisfies Action<string>;
  });

  return (
    <ActionSheet
      actions={actions}
      icon={<ArrowRightIcon />}
      btnSizingClassName='w-6 h-6'
    />
  );
}

function AnalyticsProgramsIntros(props: {
  program: DtoBasicProgram;
  channels: DtoChannel[];
  summary: DtoProgramSummaryIntros;
}) {
  const { program, channels, summary } = props;

  return (
    <AnalyticsProgramLayout
      program={program}
      channels={channels}
      className='relative'
    >
      <StatsBox
        title={summary.roundsCount}
        subtitle={`${pluralize('Round', summary.roundsCount)} So Far`}
      />

      <StatsBox
        title={summary.introsCount}
        subtitle={`${pluralize('Intro', summary.introsCount)} Made`}
      />

      <StatsBox title={''} subtitle={''} />

      <div className='absolute right-4'>
        <AnalyticsProgramsIntrosAction channels={channels} />
      </div>
    </AnalyticsProgramLayout>
  );
}

function AnalyticsProgramsWaterCooler(props: {
  program: DtoBasicProgram;
  channels: DtoChannel[];
  summary: DtoProgramSummaryWaterCooler;
}) {
  const { program, channels, summary } = props;

  return (
    <AnalyticsProgramLayout program={program} channels={channels}>
      <StatsBox
        title={summary.pastRoundsCount}
        subtitle={`${pluralize('Topic', summary.pastRoundsCount)} Shared`}
      />

      <StatsBox
        title={summary.responsesCount}
        subtitle={`Team ${pluralize('Response', summary.responsesCount)}`}
      />

      <StatsBox title={''} subtitle={''} />
    </AnalyticsProgramLayout>
  );
}

function AnalyticsProgramsCelebrations(props: {
  program: DtoBasicProgram;
  channels: DtoChannel[];
  summary: DtoProgramSummaryCelebrations;
}) {
  const { program, channels, summary } = props;

  return (
    <AnalyticsProgramLayout program={program} channels={channels}>
      <StatsBox
        title={summary.datesCelebratedCount}
        subtitle={`${pluralize(
          'Date',
          summary.datesCelebratedCount
        )} Celebrated`}
      />

      <StatsBox
        title={summary.responsesCount}
        subtitle={`Team ${pluralize('Response', summary.responsesCount)}`}
      />

      <StatsBox title={''} subtitle={''} />
    </AnalyticsProgramLayout>
  );
}

function AnalyticsProgramsRecognition(props: {
  program: DtoBasicProgram;
  slackChannels: SlackChannel[];
  summary: DtoProgramSummaryRecognition;
}) {
  const { program, summary, slackChannels } = props;

  return (
    <AnalyticsProgramLayout program={program} channels={slackChannels}>
      <StatsBox
        title={summary.givenCount}
        subtitle={`${pluralize('Ice Cream', summary.givenCount)} Given`}
      />

      <StatsBox
        title={summary.giversCount}
        subtitle={`Total ${pluralize('Giver', summary.giversCount)}`}
      />

      <StatsBox
        title={summary.receiversCount}
        subtitle={`Total ${pluralize('Receiver', summary.receiversCount)}`}
      />
    </AnalyticsProgramLayout>
  );
}

async function loaderData(orgId: string, timeRange: TimeRangeKey) {
  const [channels, summary, slackChannels] = await Promise.all([
    (await apiService.channel.queryChannels(orgId)).data.channels,
    (
      await apiService.program.queryProgramsSummary(orgId, {
        timeRange: timeRange,
        startAt: null,
        endAt: null,
      })
    ).data,
    (
      await apiService.slack
        .queryChannels({
          type: 'byUserId',
          orgId: orgId,
        })
        .catch(() => ({ data: { channels: [] } }))
    ).data.channels,
  ]);

  return {
    channels,
    summary,
    slackChannels,
  };
}

export function AnalyticsPrograms(props: { searchParams: AnalyticsFilter }) {
  const { data, isLoading } = useSWRImmutable(
    ['/analytics/programs', props.searchParams],
    async () =>
      loaderData(props.searchParams.orgId, props.searchParams.timeRange)
  );
  const features = useMemo(() => {
    const features: JSX.Element[] = [];
    if (!data || !data.channels) return features;

    {
      const channels = getInstalledChannels(
        data.channels,
        EnumsProgramType.ProgramTypeGlobalPairing
      );
      const program = getBasicProgram(
        data.channels,
        EnumsProgramType.ProgramTypeGlobalPairing
      );
      if (!!program && channels.length > 0) {
        features.push(
          <AnalyticsProgramsGlobalPairing
            key={EnumsProgramType.ProgramTypeGlobalPairing}
            program={program}
            channels={channels}
            summary={data.summary.globalPairing}
          />
        );
      }
    }

    {
      const channels = getInstalledChannels(
        data.channels,
        EnumsProgramType.ProgramTypeIntros
      );
      const program = getBasicProgram(
        data.channels,
        EnumsProgramType.ProgramTypeIntros
      );
      if (!!program && channels.length > 0) {
        features.push(
          <AnalyticsProgramsIntros
            key={EnumsProgramType.ProgramTypeIntros}
            program={program}
            channels={channels}
            summary={data.summary.intros}
          />
        );
      }
    }

    {
      const channels = getInstalledChannels(
        data.channels,
        EnumsProgramType.ProgramTypeWaterCooler
      );
      const program = getBasicProgram(
        data.channels,
        EnumsProgramType.ProgramTypeWaterCooler
      );
      if (!!program && channels.length > 0) {
        features.push(
          <AnalyticsProgramsWaterCooler
            key={EnumsProgramType.ProgramTypeWaterCooler}
            program={program}
            channels={channels}
            summary={data.summary.waterCooler}
          />
        );
      }
    }

    {
      const channels = getInstalledChannels(
        data.channels,
        EnumsProgramType.ProgramTypeBirthdayAndCelebrations
      );
      const program = getBasicProgram(
        data.channels,
        EnumsProgramType.ProgramTypeBirthdayAndCelebrations
      );
      if (!!program && channels.length > 0) {
        features.push(
          <AnalyticsProgramsCelebrations
            key={EnumsProgramType.ProgramTypeBirthdayAndCelebrations}
            program={program}
            channels={channels}
            summary={data.summary.celebrations}
          />
        );
      }
    }

    {
      const program = getBasicProgram(
        data.channels,
        EnumsProgramType.ProgramTypeRecognition
      );
      if (!!program) {
        features.push(
          <AnalyticsProgramsRecognition
            key={EnumsProgramType.ProgramTypeRecognition}
            program={program}
            slackChannels={data.slackChannels}
            summary={data.summary.recognition}
          />
        );
      }
    }

    return features;
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }
  if (features.length === 0)
    return <div className='w-full text-secondary'>No programs found.</div>;

  return (
    <div className='w-full p-4 bg-secondary rounded-xl text-white'>
      <header className='flex justify-between items-center'>
        <p className='text-1.5xl font-bold'>Programs Engagement Summary</p>
      </header>
      <main className='mt-5 w-full'>
        <p className='text-base font-bold'>Program</p>
        <div className='mt-2.5 flex flex-col gap-2.5'>{features}</div>
      </main>
    </div>
  );
}
