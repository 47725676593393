import { curveLinear } from '@visx/curve';
import { AreaSeries, Axis, darkTheme, Grid, XYChart } from '@visx/xychart';
import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';

import { type OrgDailySummary } from '../../services/api-service/analytics.api';

export type GameTrendProps = {
  height: number;
};

export interface GamePlayed {
  date: Date;
  played: number;
}

function transform(summaries: OrgDailySummary[]): GamePlayed[] {
  if (summaries.length === 0) return [];
  const sortedSummaries = summaries.sort((a, b) => (a.date > b.date ? 1 : -1));

  return sortedSummaries.map((s) => {
    return {
      date: parseISO(s.date),
      played: s.previousSessionCount + s.sessionCount,
    };
  });
}

const formatDate = (d: Date, weekOrMonth: 'week' | 'month') => {
  if (weekOrMonth === 'week') return format(d, 'M/d/yy');
  return format(d, 'MMM yyyy');
};

const RECENT_DAYS = 1000 * 60 * 60 * 24 * 90;

export function GameTrend(props: {
  summaries: OrgDailySummary[];
  width?: number;
  height?: number;
}): JSX.Element {
  const { summaries } = props;

  const data: GamePlayed[] = useMemo(() => transform(summaries), [summaries]);

  const minYValue = data.length > 0 ? data[0].played : 0;
  const maxYValue = data.length > 0 ? data[data.length - 1].played : 0;

  darkTheme.colors = ['#8C6FFF'];

  const weekOrMonth =
    data.length > 0 && Date.now() - data[0].date.getTime() < RECENT_DAYS
      ? 'week'
      : 'month';

  return (
    <XYChart
      theme={darkTheme}
      xScale={{ type: 'band', paddingInner: 1 }}
      yScale={{ type: 'linear', domain: [minYValue, maxYValue], zero: false }}
      width={props.width}
      height={props.height}
      captureEvents={false}
    >
      <Grid
        columns={false}
        numTicks={4}
        lineStyle={{
          stroke: '#313436',
          strokeLinecap: 'round',
          strokeWidth: 1,
        }}
        strokeDasharray='2, 3'
      />
      <AreaSeries
        dataKey='played'
        data={data}
        xAccessor={(d) => d.date}
        yAccessor={(d) => d.played}
        fillOpacity={0.1}
        curve={curveLinear}
        lineProps={{
          strokeWidth: 6,
          strokeLinecap: 'round',
        }}
      />
      <Axis
        key={`time-axis`}
        orientation='bottom'
        numTicks={3}
        hideAxisLine={true}
        hideTicks={true}
        tickFormat={(d) => formatDate(d, weekOrMonth)}
      />
      <Axis
        key={`value-axis`}
        orientation='left'
        numTicks={3}
        hideAxisLine={true}
        hideTicks={true}
        tickFormat={(d) => `${Math.round(d)}`}
      />
    </XYChart>
  );
}
