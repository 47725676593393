import { fromMediaDTO } from '../../../utils/api-dto';
import { xDomainifyUrl } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import {
  addSlideLayout,
  type AnalyticsSlideSharedProps,
  pxToInch,
} from './AnalyticsSlideShared';

export function addSlideNewGames(props: AnalyticsSlideSharedProps) {
  const { pres, summary } = props;

  const images = summary.newFeaturedGamePacks
    .map((gamePack) => {
      return MediaUtils.PickMediaUrl(fromMediaDTO(gamePack.cover));
    })
    .filter((url) => url !== null)
    .map((u) => xDomainifyUrl(u, 'analytics-slides'));
  if (images.length === 0) {
    return;
  }

  const slide = pres.addSlide();

  addSlideLayout({
    ...props,
    slide,
    title: 'What’s New On Luna Park',
    subtitle: 'Recently featured experiences on Luna Park',
  });

  const width = 400;
  const height = 225;
  const gap = 40;

  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    const x = 100 + (i % 4) * (width + gap);
    const y = 240 + Math.floor(i / 4) * (height + gap);

    slide.addImage({
      path: image,
      x: pxToInch(x),
      y: pxToInch(y),
      w: pxToInch(width),
      h: pxToInch(height),
    });
  }
}
