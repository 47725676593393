import { type ReactNode, useMemo } from 'react';
import Slider from 'react-slick';
import useSWRImmutable from 'swr/immutable';

import love from '../../assets/img/Love.png';
import { apiService } from '../../services/api-service';
import { type OrgDailySummary } from '../../services/api-service/analytics.api';
import type { JoyCapture as JoyCaptureType } from '../../types/joyCapture';
import { ArrowNext, ArrowPrev } from '../Game/GameCenter';
import { InfoIcon } from '../icons/InfoIcon';
import {
  JoyCapture,
  type JoyCaptureRenderer,
  useJoyCaptureRenderer,
} from '../JoyCapture';
import { Loading } from '../Loading';
import { type AnalyticsFilter } from '../Organization/Details/Analytics';

const JOY_CAPTURE_CHUNK_SIZE = 19;

const JOY_CAPTURE_ONE_LINE_MAX_SIZE = 10;

const MAX_JOY_CAPTURE = JOY_CAPTURE_CHUNK_SIZE * 3;

export const AnalyticsToolTips = (props: {
  className?: string;
  children?: ReactNode;
  postion?: 'middle' | 'left';
}): JSX.Element => {
  return (
    <div className={`mt-6 w-2 h-2 ${props.className}`}>
      <div className='relative group'>
        <div
          className={`absolute p-2 bottom-6 ${
            props.postion === 'left' ? '-right-20' : '-right-40'
          } w-80 z-10 text-sm font-medium border border-secondary rounded-lg bg-black  text-white group-hover:visible invisible`}
        >
          {props.children}
        </div>
        <div className='cursor-pointer'>
          <InfoIcon className='w-5 h-5' color='gray' />
        </div>
      </div>
    </div>
  );
};

function splitJoyCaptures(props: {
  reports: JoyCaptureType[];
  numberOfLine: number;
}): JoyCaptureType[][] {
  const { reports, numberOfLine } = props;

  if (reports.length <= JOY_CAPTURE_ONE_LINE_MAX_SIZE) {
    return [reports];
  }

  const results = [];

  const joyCapturePerLine = Math.ceil(reports.length / numberOfLine);
  for (let line = 0; line < numberOfLine; line++) {
    const reportLine: JoyCaptureType[] = [];
    for (let i = 0; i < joyCapturePerLine; i++) {
      const value = reports[i + line * joyCapturePerLine];
      if (!value) continue;
      reportLine.push(value);
    }
    results.push(reportLine);
  }
  return results;
}

function AnalyticsJoyCaptureSlide(props: {
  joyCaptures: JoyCaptureType[];
  renderer: JoyCaptureRenderer;
}): JSX.Element {
  const { joyCaptures } = props;

  const joyCaptureChunks = useMemo(() => {
    const chunks: JoyCaptureType[][] = [];
    for (let i = 0; i < joyCaptures.length; i += JOY_CAPTURE_CHUNK_SIZE) {
      chunks.push(joyCaptures.slice(i, i + JOY_CAPTURE_CHUNK_SIZE));
    }
    return chunks;
  }, [joyCaptures]);

  const slides: JSX.Element[] = [];
  for (let i = 0; i < joyCaptureChunks.length; i += 1) {
    slides.push(
      <div key={`slide-1-${i}`} className='flew flew-row h-60'>
        {splitJoyCaptures({
          reports: joyCaptureChunks[i],
          numberOfLine: 2,
        }).map((c, ic) => {
          return (
            <div key={`chunk-${i}-${ic}`} className='h-20 flex justify-center'>
              {c.map((r) => {
                return (
                  <JoyCapture
                    key={r.uid}
                    joyCapture={r}
                    renderer={props.renderer}
                    styles={{ size: 'p-2.5 w-24 h-24' }}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <Slider
      adaptiveHeight
      infinite={true}
      speed={400}
      autoplay={true}
      autoplaySpeed={30000}
      slidesToShow={1}
      slidesToScroll={1}
      nextArrow={<ArrowNext />}
      prevArrow={<ArrowPrev />}
      easing='ease'
    >
      {slides}
    </Slider>
  );
}

export function AnalyticsJoyCapture(props: {
  summaries: OrgDailySummary[] | null;
  searchParams: AnalyticsFilter;
}): JSX.Element {
  const { summaries, searchParams } = props;
  const renderer = useJoyCaptureRenderer();

  const sumariesTimes = useMemo(() => {
    if (!summaries) return 0;
    return summaries.reduce((s, a) => s + a.sessionSeconds, 0);
  }, [summaries]);

  const hours = useMemo(
    () => Math.floor(sumariesTimes / 3600),
    [sumariesTimes]
  );

  const minutes = useMemo(
    () => Math.floor((sumariesTimes % 3600) / 60),
    [sumariesTimes]
  );

  const { isValidating, data } = useSWRImmutable(
    `/organizations/${searchParams.orgId}/joyful-moments`,
    async () => {
      const resp = await apiService.organization.getJoyfulMoments(
        searchParams.orgId,
        MAX_JOY_CAPTURE
      );
      return resp.data.joyCaptures;
    }
  );

  if (isValidating) return <Loading containerClassName='h-80' />;

  return (
    <div className='mt-3.5 text-white w-full relative'>
      <div className='flex flex-row justify-center text-tertiary'>
        <img src={love} className='w-22.5' alt='logo' />
        <div className='flex mt-5'>
          {hours > 0 && (
            <div className='text-5xl pr-3'>{hours.toLocaleString()}</div>
          )}
          {hours === 1 && <div className='text-2xl mt-4'>hr</div>}
          {hours > 1 && <div className='text-2xl mt-4'>hrs</div>}
          <div className='text-5xl pl-3 pr-3'>{minutes}</div>
          <div className='text-2xl mt-4 pr-3'>{`${
            minutes > 1 ? 'mins' : 'min'
          } of Total Quality Time`}</div>
          <AnalyticsToolTips>
            <p>
              Total usage minutes per employee over the time period selected.
            </p>
            <br />
            <p>
              Example: if 3 employees spend 10 minutes in a Luna Park
              experience, that counts as 30 minutes of quality time.
            </p>
          </AnalyticsToolTips>
        </div>
      </div>
      {data && (
        <AnalyticsJoyCaptureSlide joyCaptures={data} renderer={renderer} />
      )}
      {data && data.length > 0 && (
        <div className='absolute top-70 left-90'>
          Joyful moments your team has had during their Luna Park Experiences
        </div>
      )}
    </div>
  );
}
