import { Link } from '@remix-run/react';
import pluralize from 'pluralize';
import { type CSSProperties, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';
import useSWRImmutable from 'swr/immutable';

import Victory from '../../assets/img/Victory.png';
import Wave from '../../assets/img/Wave.png';
import { apiService } from '../../services/api-service';
import { type OrgDailySummary } from '../../services/api-service/analytics.api';
import { getStaticAssetPath } from '../../utils/assets';
import { DoubleRightArrow } from '../icons/Arrows';
import { CalendarIcon } from '../icons/CalendarIcon';
import { type AnalyticsFilter } from '../Organization/Details/Analytics';
import { AnalyticsToolTips } from './AnalyticsJoyCapture';
import { GameTrend } from './GameTrend';

export interface CircleProps {
  progress: number;
  animate?: boolean;
  animationDuration?: string;
  showPercentage?: boolean;
  showPercentageSymbol?: boolean;
  progressColor?: string;
  bgColor?: string;
  textColor?: string;
  size?: string;
  lineWidth?: string;
  percentSpacing?: number;
  textStyle?: CSSProperties;
  roundedStroke?: boolean;
  onAnimationEnd?(): void;
}

function Circle(props: CircleProps): JSX.Element {
  const {
    progress,
    size,
    progressColor,
    lineWidth,
    animate,
    animationDuration,
    roundedStroke,
    onAnimationEnd,
  } = props;
  const radius = 175;
  const diameter = Math.round(Math.PI * radius * 2);
  const getOffset = (val = 0) =>
    Math.round(((100 - Math.min(val, 100)) / 100) * diameter);
  const strokeDashoffset = getOffset(progress);
  const transition = animate
    ? `stroke-dashoffset ${animationDuration} ease-out`
    : undefined;
  const strokeLinecap = roundedStroke ? 'round' : 'butt';
  const svgSize = size;

  return (
    <div>
      <img
        src={Victory}
        className='absolute top-6 left-6 w-26 h-26'
        alt='logo'
      />
      <svg
        width={svgSize}
        height={svgSize}
        viewBox='-25 -25 400 400'
        className='fill-current text-pink-001 stroke-current'
      >
        <circle
          stroke={progressColor}
          transform='rotate(-90 175 175)'
          cx='175'
          cy='175'
          r='175'
          strokeDasharray='1100'
          strokeWidth={lineWidth}
          strokeDashoffset='1100'
          strokeLinecap={strokeLinecap}
          fill='none'
          style={{ strokeDashoffset, transition }}
          onTransitionEnd={onAnimationEnd}
        />
        <circle
          fillOpacity={0.2}
          className='fill-red'
          stroke={progressColor}
          transform='rotate(-90 175 175)'
          cx='175'
          cy='175'
          r='135'
          strokeDasharray='1100'
          strokeWidth={lineWidth}
          strokeDashoffset='1100'
          strokeLinecap={strokeLinecap}
          onTransitionEnd={onAnimationEnd}
        />
      </svg>
    </div>
  );
}

function ParticipationRatio(props: { percent: number }): JSX.Element {
  const { percent } = props;
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProgress(percent);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [percent]);

  return (
    <div>
      <Circle
        animate={false} // Boolean: Animated/Static progress
        animationDuration='2s' // String: Length of animation
        size='150' // String: Defines the size of the circle.
        lineWidth='25' // String: Defines the thickness of the circle's stroke.
        progress={progress} // String: Update to change the progress and percentage.
        progressColor='pink-001' // String: Color of "progress" portion of circle.
        bgColor='transparent' // String: Color of "empty" portion of circle.
        textColor='transparent' // String: Color of percentage text color.
        textStyle={{
          font: 'bold 4rem Helvetica, Arial, sans-serif', // CSSProperties: Custom styling for percentage.
        }}
        percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
        roundedStroke={true} // Boolean: Rounded/Flat line ends
        showPercentage={true} // Boolean: Show/hide percentage.
        showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
      />
    </div>
  );
}

export function AnalyticsParticipantPercent(props: {
  searchParams: AnalyticsFilter;
}): JSX.Element {
  const { searchParams } = props;

  const { data } = useSWRImmutable(
    [
      '/analytics/org-participant-percent',
      searchParams.orgId,
      searchParams.timeRange,
    ],
    async () => {
      const resp = await apiService.analytics.getOrgParticipantPercent({
        orgId: searchParams.orgId,
        timeRange: searchParams.timeRange,
      });
      return resp.data;
    },
    {
      shouldRetryOnError: false,
    }
  );

  return (
    <div className='bg-modal w-1/2 h-62 mt-2 rounded-2xl relative'>
      <div className='absolute top-6 left-3'>
        <ParticipationRatio percent={data?.percent ?? 0} />
      </div>
      <div className='absolute top-15 left-45 flex flex-row font-bold text-pink-001'>
        <div className='text-5xl pr-3'>{data?.percent ?? 0}%</div>
        <div className='text-xl mt-5 pr-3'>Participation</div>
      </div>
      <div className='absolute top-28 left-45 text-gray-300'>
        Employee engagements with LP
      </div>
      <AnalyticsToolTips className='absolute top-2 right-12'>
        <p>
          Percentage of employees who participated in a Luna Park experience
          during the selected period.
        </p>
      </AnalyticsToolTips>
    </div>
  );
}

function AnalyticsConnections(props: { summaries: OrgDailySummary[] | null }) {
  const { summaries } = props;

  const connectionCount = useMemo(() => {
    if (!summaries) return 0;
    return summaries.reduce((s, a) => s + a.connectionCount, 0);
  }, [summaries]);

  return (
    <div className='bg-modal w-1/2 h-60 relative rounded-2xl'>
      <img src={Wave} className='absolute w-26 h-26 top-10 left-8' alt='logo' />
      <div
        className={`absolute top-15 ${
          connectionCount < 1000
            ? 'left-45'
            : connectionCount < 10000
            ? 'right-5'
            : 'right-0'
        } flex flex-row font-bold text-blue-005`}
      >
        <div className='text-5xl pr-3'>{connectionCount.toLocaleString()}</div>
        <div className='text-xl mt-5 pr-3'>Coworker Connections</div>
      </div>
      <div className='absolute top-28 left-45 text-gray-300'>
        Teammates who connected on Luna Park
      </div>
      <AnalyticsToolTips className='absolute top-2 right-12'>
        <p>
          Total number of non-unique connections made on Luna Park together.
        </p>
        <br />
        <p>
          Example: If 10 employees are in an experience that would count as 10
          connections regardless of whether those employees have participated
          together before or not.
        </p>
      </AnalyticsToolTips>
    </div>
  );
}

function AnalyticsCumulativeSessions(props: {
  summaries: OrgDailySummary[] | null;
  width: string;
}) {
  const { summaries } = props;
  const sessionCount = useMemo(() => {
    if (!summaries) return 0;
    return summaries.reduce((s, a) => s + a.sessionCount, 0);
  }, [summaries]);
  const windowSize = useWindowSize();
  return (
    <div className={`bg-modal ${props.width} h-full ml-2 rounded-2xl relative`}>
      <div className='absolute font-bold flex flew-col top-8 left-10'>
        <div className='text-5xl pr-3  text-[#8C6FFF]'>
          {sessionCount.toLocaleString()}
        </div>
        <div className='text-xl mt-5 pr-3 text-[#8C6FFF]'>
          Cumulative Experiences
        </div>
      </div>
      <div className='mt-15'>
        {summaries && <GameTrend summaries={summaries} />}
      </div>

      {summaries && (
        <div className='w-full flex justify-center'>
          <Link
            to={'./sessions'}
            className='flex justify-center items-center gap-2 btn-secondary w-70 h-15'
          >
            <DoubleRightArrow className='w-7.5 h-7.5 fill-current' />
            See All Experiences
          </Link>
        </div>
      )}

      <AnalyticsToolTips
        className='absolute top-2 right-12 '
        postion={windowSize.width < 1366 ? 'left' : 'middle'}
      >
        <p>Cumulative number of experiences over the time period selected.</p>
        <br />
        <p>
          Example: if 35 employees join a Luna Park live or On Demand
          experience, that counts as 1.
        </p>
      </AnalyticsToolTips>
    </div>
  );
}

export function AnalyticsSummary(props: {
  summaries: OrgDailySummary[] | null;
  searchParams: AnalyticsFilter;
  showConnection?: boolean;
  showParticipantPercent?: boolean;
}): JSX.Element {
  const { summaries, searchParams } = props;
  return (
    <div className='content-center w-full h-124 flex flex-col flex-wrap'>
      {props.showConnection && <AnalyticsConnections summaries={summaries} />}
      {props.showParticipantPercent && (
        <AnalyticsParticipantPercent searchParams={searchParams} />
      )}
      <AnalyticsCumulativeSessions
        summaries={summaries}
        width={
          props.showConnection || props.showParticipantPercent
            ? 'w-1/2'
            : 'w-full'
        }
      />
    </div>
  );
}

function Uptick() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='28'
      viewBox='0 0 32 28'
      fill='none'
    >
      <path
        d='M14.2679 1C15.0377 -0.333333 16.9622 -0.333334 17.732 1L31.5885 25C32.3583 26.3333 31.396 28 29.8564 28H2.14359C0.603993 28 -0.358258 26.3333 0.411542 25L14.2679 1Z'
        fill='#39D966'
      />
    </svg>
  );
}

function calSessionCount(summary: OrgDailySummary, onlyPrevious = false) {
  if (onlyPrevious) return summary.previousSessionCount;
  return summary.sessionCount + summary.previousSessionCount;
}

export function AnalyticsSummaryV2(props: {
  summaries: OrgDailySummary[];
  upcomingEventsCount: number;
  recentNSummaryDataPoints?: number;
}): JSX.Element {
  const { summaries, upcomingEventsCount, recentNSummaryDataPoints } = props;
  const totalSessions =
    summaries.length > 0
      ? calSessionCount(summaries[summaries.length - 1]) -
        calSessionCount(summaries[0], true)
      : 0;

  const recentSummaries = useMemo(() => {
    if (!recentNSummaryDataPoints || !summaries) return [];
    if (summaries.length < recentNSummaryDataPoints) {
      return summaries;
    }
    return summaries.slice(summaries.length - recentNSummaryDataPoints);
  }, [summaries, recentNSummaryDataPoints]);

  return (
    <div className='w-full h-120 flex flex-col items-center gap-4 bg-modal rounded-2.5xl p-7.5'>
      <header className='w-full flex items-center'>
        <div className='w-1/3'>
          {upcomingEventsCount > 0 && (
            <div className='text-lg text-red-006 font-bold transform -translate-y-2 flex items-center gap-1.5'>
              <CalendarIcon className='w-4.5 h-4.5 fill-current' />
              {upcomingEventsCount} Upcoming Scheduled{' '}
              {pluralize('Experience', upcomingEventsCount)}
            </div>
          )}
        </div>
        <div className='w-1/3 text-3.5xl font-bold text-center'>
          Experiences
        </div>
        <div className='w-1/3'></div>
      </header>
      <main className='w-full h-full flex'>
        <img
          className='w-1/3'
          src={getStaticAssetPath('images/analytics/TeamBuilding.png')}
          alt='Team Building'
        />
        <div className='w-1/3 flex flex-col items-center justify-center gap-4'>
          <div className='flex items-center gap-8'>
            <div className='text-7.5xl font-bold text-tertiary'>
              {totalSessions}
            </div>
            {totalSessions > 0 && <Uptick />}
          </div>
          <div className='font-bold tracking-[1.25px]'>Experiences Played</div>
        </div>
        <div className='w-1/3 relative'>
          <GameTrend summaries={recentSummaries} height={320} />
          <div className='w-full text-icon-gray text-sm font-bold text-center absolute top-[320px]'>
            Cumulative Over Recent Time Range
          </div>
        </div>
      </main>
    </div>
  );
}
