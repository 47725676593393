import { Link } from '@remix-run/react';
import { useParams } from '@remix-run/react';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import {
  type DtoChannel,
  type DtoChannelProgramLink,
  type DtoProgramRoundGroup,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { type Organization, OrganizerUtils, SessionUtils } from '../../types';
import { fromDTOOrganizer } from '../../utils/api-dto';
import { Loading } from '../Loading';
import { useOrgFeatureContext } from '../Organization';
import { castProgramRound, type IntrosProgramRound } from '../Program';
import { AnalyticsProgramDetailsLayout } from './AnalyticsShared';

function GroupRow(props: { group: DtoProgramRoundGroup }) {
  const { group } = props;

  const sessions = useMemo(
    () => group.data.sessions?.filter((session) => !!session.groupPhoto) || [],
    [group.data.sessions]
  );

  return (
    <div
      key={group.id}
      className='w-full bg-layer-001 rounded-lg flex items-center'
    >
      <div className='w-2/6 p-5 flex flex-col gap-2'>
        {group.data.players.map((player) => (
          <p key={player.uid} className='text-sms text-white'>
            {OrganizerUtils.GetDisplayName(fromDTOOrganizer(player.organizer))}
          </p>
        ))}
      </div>
      <div className='w-1/6'>
        {group.engaged ? (
          <p className='text-sms text-green-001'>Met</p>
        ) : (
          <p className='text-sms text-icon-gray'>Didn't meet</p>
        )}
      </div>
      <div className='w-1/6'>
        {group.data.talked && <p className='text-sms text-green-002'>✓</p>}
      </div>
      <div className='w-2/6'>
        {sessions.length > 0 ? (
          <div className='flex flex-col pr-10'>
            {sessions.map((session) => (
              <a
                key={session.id}
                href={SessionUtils.ComposeMemoriesUrl(session.id)}
                className='text-sms text-icon-gray underline truncate'
              >{`${window.origin}${SessionUtils.ComposeMemoriesUrl(
                session.id
              )}`}</a>
            ))}
          </div>
        ) : (
          <p className='text-sms text-icon-gray'>N/A</p>
        )}
      </div>
    </div>
  );
}

function AnalyticsIntrosRoundDetails(props: {
  organization: Organization;
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
  round: IntrosProgramRound;
  groups: DtoProgramRoundGroup[];
}) {
  const { organization, channel, programLink, round, groups } = props;
  const { routePrefix } = useOrgFeatureContext();

  const groupsCount = round.extensions?.groupsCount || 0;
  const completedGroupsCount = round.extensions?.completedGroupsCount || 0;
  const completedPercentage =
    groupsCount > 0 ? Math.ceil((completedGroupsCount / groupsCount) * 100) : 0;

  return (
    <AnalyticsProgramDetailsLayout
      organization={organization}
      programLink={programLink}
    >
      <main className='mt-6 w-full'>
        <div className='flex items-center gap-2.5'>
          <Link
            to={`${routePrefix}/analytics/programs/${programLink.id}`}
            className='text-2xl font-medium text-icon-gray hover:underline hover:text-white'
          >
            #{channel.name}
          </Link>
          <p className='text-2xl font-medium text-icon-gray'>/</p>
          <p className='text-2xl font-medium'>
            {format(new Date(round.startedAt || ''), 'EEE, d MMM yyyy')}
          </p>
        </div>

        <div className='mt-12 w-full h-40 flex items-center gap-2.5'>
          <div
            className='
              w-1/3 h-full bg-layer-001 rounded-2.5xl flex flex-col justify-center items-center gap-2.5
              text-blue-005
            '
          >
            <p className='text-4xl font-bold'>{groupsCount}</p>
            <p className='font-bold'>{pluralize('Intro', groupsCount)} made</p>
          </div>
          <div
            className='
              w-1/3 h-full bg-layer-001 rounded-2.5xl flex flex-col justify-center items-center gap-2.5
            text-tertiary
            '
          >
            <p className='text-4xl font-bold'>{completedGroupsCount}</p>
            <p className='font-bold'>
              {pluralize('Group', completedGroupsCount)} met
            </p>
          </div>
          <div
            className='
              w-1/3 h-full bg-layer-001 rounded-2.5xl flex flex-col justify-center items-center gap-2.5
              text-pink-001
            '
          >
            <p className='text-4xl font-bold'>{completedPercentage}%</p>
            <p className='font-bold'>of groups met</p>
          </div>
        </div>

        <div className='mt-12 w-full'>
          <div className='w-full flex text-sms text-icon-gray'>
            <p className='w-2/6'>Group</p>
            <p className='w-1/6'>States</p>
            <p className='w-1/6'>Talked in Slack</p>
            <p className='w-2/6'>Photo Memories</p>
          </div>
          <div className='mt-5 w-full flex flex-col gap-2.5'>
            {groups.map((group) => (
              <GroupRow key={group.id} group={group} />
            ))}
          </div>
        </div>
      </main>
    </AnalyticsProgramDetailsLayout>
  );
}

async function loadData(programLinkId: string, roundId: string) {
  const [channel, rounds, groups] = await Promise.all([
    (
      await apiService.channel.getChannelByProgramLinkId(programLinkId)
    ).data.channel,
    (await apiService.program.queryRounds(programLinkId)).data.programRounds,
    (await apiService.program.queryGroups(roundId)).data.groups,
  ]);
  const programLink = channel.programLinks.find(
    (link) => link.id === programLinkId
  );
  if (!programLink) throw new Error('program link not found');
  const round = rounds.find((round) => round.id === roundId);
  if (!round) throw new Error('round not found');

  return {
    channel,
    programLink,
    round,
    groups,
  };
}

export function AnalyticsProgramRoundDetails(props: {
  organization: Organization;
}) {
  const { organization } = props;
  const { programLinkId = '', roundId = '' } = useParams<
    'programLinkId' | 'roundId'
  >();
  const { data, isLoading } = useSWRImmutable(
    [`/analytics/programs/${programLinkId}/rounds/${roundId}`],
    async () => loadData(programLinkId, roundId)
  );

  if (isLoading || !data)
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <Loading />
      </div>
    );

  switch (data.programLink.programType) {
    case EnumsProgramType.ProgramTypeIntros:
      return (
        <AnalyticsIntrosRoundDetails
          organization={organization}
          channel={data.channel}
          programLink={data.programLink}
          round={castProgramRound<IntrosProgramRound>(data.round)}
          groups={data.groups}
        />
      );
    default:
      return null;
  }
}
