import pluralize from 'pluralize';
import type pptxgen from 'pptxgenjs';

import {
  addBenchmarkCard,
  addSlideLayout,
  type AnalyticsSlideSharedProps,
  pxToInch,
  pxToPt,
} from './AnalyticsSlideShared';

function addCard(props: {
  pres: pptxgen;
  slide: pptxgen.Slide;
  x: number;
  y: number;
  width: number;
  height: number;
  icon: string;
  text: string | pptxgen.TextProps[];
}) {
  const { pres, slide, icon, text, x, y, width, height } = props;

  slide.addShape(pres.ShapeType.roundRect, {
    fill: { color: '101012' },
    rectRadius: pxToInch(20),
    x: pxToInch(x),
    y: pxToInch(y),
    w: pxToInch(width),
    h: pxToInch(height),
  });
  if (icon.startsWith('http')) {
    slide.addImage({
      path: icon,
      x: pxToInch(x + 30),
      y: pxToInch(y + 30),
      w: pxToInch(90),
      h: pxToInch(90),
    });
  } else {
    slide.addText(icon, {
      x: pxToInch(x + 30),
      y: pxToInch(y + 30),
      w: pxToInch(90),
      h: pxToInch(90),
      color: 'FFFFFF',
      fontSize: pxToPt(64),
      align: 'center',
      valign: 'middle',
    });
  }
  slide.addText(text, {
    x: pxToInch(x + 30),
    y: pxToInch(y + 140),
    w: pxToInch(width - 60),
    h: pxToInch(height - 170),
    color: 'FFFFFF',
    fontSize: pxToPt(28),
    align: 'left',
    valign: 'top',
  });
}

function cardTitle(title: string) {
  return {
    text: title,
    options: {
      fontSize: pxToPt(36),
      bold: true,
    },
  };
}

function cardTextHighLight(text: string) {
  return {
    text,
    options: {
      color: 'FBB707',
      bold: true,
    },
  };
}

function cardText(text: string) {
  return {
    text,
  };
}

function breakLine() {
  return {
    text: '\n',
  };
}

export function addSlideLPUpdate(props: AnalyticsSlideSharedProps) {
  const { pres, summary } = props;

  const showCards = {
    teamHangsAndEvents: summary.sessionSeconds > 0,
    celebrations: summary.celebrations.datesCelebratedCount > 0,
    waterCooler: summary.waterCooler.pastRoundsCount > 0,
    intros: summary.intros.roundsCount > 0,
    recognition: summary.recognition.givenCount > 0,
  };
  const showCount = Object.values(showCards).filter(Boolean).length;
  if (showCount === 0) {
    return;
  }

  const slide = pres.addSlide();

  addSlideLayout({
    ...props,
    slide,
    title: 'Your Luna Park Update',
    subtitle: 'Culture building with Luna Park',
  });

  const width =
    {
      5: 350,
      4: 420,
      3: 540,
      2: 540,
      1: 800,
    }[showCount] || 350;
  const gap = 10;
  let x = (1920 - showCount * width - (showCount - 1) * gap) / 2;
  const y = 240;
  const height = 680;
  const benchmarkHeight =
    {
      5: 180,
      4: 180,
    }[showCount] || 158;

  if (showCards.teamHangsAndEvents) {
    const hours = Math.ceil(summary.sessionSeconds / 3600);
    addCard({
      pres,
      slide,
      x,
      y,
      width,
      height,
      icon: '👏',
      text: [
        cardTitle('Team Hangs & Events'),
        breakLine(),
        breakLine(),
        cardText('So far we’ve had'),
        cardTextHighLight(` ${hours.toLocaleString()} `),
        cardText(`${pluralize('hour', hours)} of Total Quality Time.`),
        breakLine(),
        breakLine(),
        cardTextHighLight(`${summary.sessionPlayers.toLocaleString()} `),
        cardText('of us have played Luna Park Experiences at least once.'),
      ],
    });
    if (summary.socialTimePercentile) {
      addBenchmarkCard({
        slide,
        x: x + 25,
        y: y + height - 120,
        w: width - 50,
        h: benchmarkHeight,
        title: `🏆 Benchmark: Top ${summary.socialTimePercentile}%!`,
        content: `${props.organization.name} is in the top ${summary.socialTimePercentile}% of all companies at Luna Park for synchronous quality time.`,
      });
    }

    x += width + gap;
  }

  if (showCards.celebrations) {
    const text = [
      cardTitle('Celebrating Each Other'),
      breakLine(),
      breakLine(),
      cardText('We’ve celebrated'),
      cardTextHighLight(
        ` ${summary.celebrations.birthdayCelebratedCount.toLocaleString()} `
      ),
      cardText(
        `${pluralize(
          'birthday',
          summary.celebrations.birthdayCelebratedCount
        )} and`
      ),
      cardTextHighLight(
        ` ${summary.celebrations.workAnniversaryCelebratedCount.toLocaleString()} `
      ),
      cardText(
        `company ${pluralize(
          'anniversary',
          summary.celebrations.workAnniversaryCelebratedCount
        )}!`
      ),
    ];
    if (summary.celebrations.responsesCount > 0) {
      text.push(
        breakLine(),
        breakLine(),
        cardText('This has lead to'),
        cardTextHighLight(
          ` ${summary.celebrations.responsesCount.toLocaleString()} `
        ),
        cardText(
          `team ${pluralize(
            'response',
            summary.celebrations.responsesCount
          )} joining in on the ${pluralize(
            'celebration',
            summary.celebrations.birthdayCelebratedCount +
              summary.celebrations.workAnniversaryCelebratedCount
          )}.`
        )
      );
    }
    addCard({
      pres,
      slide,
      x,
      y,
      width,
      height,
      icon: '🎉',
      text,
    });
    x += width + gap;
  }

  if (showCards.waterCooler) {
    addCard({
      pres,
      slide,
      x,
      y,
      width,
      height,
      icon: '💬',
      text: [
        cardTitle('Water cooler connections'),
        breakLine(),
        breakLine(),
        cardTextHighLight(
          `${summary.waterCooler.pastRoundsCount.toLocaleString()} `
        ),
        cardText(
          `conversation ${pluralize(
            'prompt',
            summary.waterCooler.pastRoundsCount
          )} have been sent spawning`
        ),
        cardTextHighLight(
          ` ${summary.waterCooler.responsesCount.toLocaleString()} `
        ),
        cardText(
          `team ${pluralize('response', summary.waterCooler.responsesCount)}`
        ),
      ],
    });
    x += width + gap;
  }

  if (showCards.intros) {
    addCard({
      pres,
      slide,
      x,
      y,
      width,
      height,
      icon: '👋',
      text: [
        cardTitle('Breaking Down Silos: Intros'),
        breakLine(),
        breakLine(),
        cardTextHighLight('We’ve had'),
        cardTextHighLight(` ${summary.intros.introsCount.toLocaleString()} `),
        cardText(`${pluralize('intro', summary.intros.introsCount)} made over`),
        cardTextHighLight(` ${summary.intros.roundsCount.toLocaleString()} `),
        cardText(
          `${pluralize('round', summary.intros.roundsCount)} of introductions`
        ),
      ],
    });
    if (summary.introsPercentile) {
      addBenchmarkCard({
        slide,
        x: x + 25,
        y: y + height - 120,
        w: width - 50,
        h: benchmarkHeight,
        title: `🏆 Benchmark: Top ${summary.introsPercentile}%!`,
        content: `${props.organization.name} is in the top ${summary.introsPercentile}% of all companies at Luna Park for intro usage.`,
      });
    }

    x += width + gap;
  }

  if (showCards.recognition) {
    addCard({
      pres,
      slide,
      x,
      y,
      width,
      height,
      icon: '🍦',
      text: [
        cardTitle('Recognition Ice Creams'),
        breakLine(),
        breakLine(),
        cardText('Over'),
        cardTextHighLight(
          ` ${summary.recognition.givenCount.toLocaleString()} `
        ),
        cardText('recognition Ice Creams given.'),
        breakLine(),
        breakLine(),
        cardTextHighLight(
          `${summary.recognition.giversCount.toLocaleString()} `
        ),
        cardText(
          `Total ${pluralize('Giver', summary.recognition.giversCount)} and`
        ),
        cardTextHighLight(
          ` ${summary.recognition.receiversCount.toLocaleString()} `
        ),
        cardText(
          `Total ${pluralize('Receiver', summary.recognition.receiversCount)}.`
        ),
      ],
    });
    x += width + gap;
  }
}
